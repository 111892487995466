import { t } from "@lingui/macro";
import {
  CopySimple,
  FolderOpen,
  Lock,
  LockOpen,
  PencilSimple,
  TrashSimple,
} from "@phosphor-icons/react";
import { ResumeDto } from "@reactive-resume/dto";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSeparator,
  ContextMenuTrigger,
} from "@reactive-resume/ui";
import { cn } from "@reactive-resume/utils";
import dayjs from "dayjs";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

import { useResumePreview } from "@/client/services/resume/preview";
import { useSubscription } from "@/client/services/user";
import { useDialog } from "@/client/stores/dialog";

import { BaseCard } from "./base-card";

type Props = {
  resume: ResumeDto;
};

export const ResumeCard = ({ resume }: Props) => {
  const frameRef = useResumePreview({
    ...resume.data,
    metadata: {
      ...resume.data.metadata,
      layout: [resume.data.metadata.layout[0]],
    },
  });

  const navigate = useNavigate();
  const { open } = useDialog<ResumeDto[]>("resume");
  const { open: lockOpen } = useDialog<ResumeDto>("lock");
  const subscription = useSubscription();
  const { open: openPremium } = useDialog("premium");

  const shouldShowPremiumLock = resume.lockedPremium && !subscription.isPro;

  const lastUpdated = dayjs().to(resume.updatedAt);

  const onOpen = (isPremiumLocked: boolean) => {
    if (isPremiumLocked) {
      openPremium("update");
    } else {
      navigate(`/builder/${resume.id}`);
    }
  };

  const onUpdate = () => {
    open("update", { id: "resume", item: [resume] });
  };

  const onDuplicate = () => {
    open("duplicate", { id: "resume", item: [resume] });
  };

  const onLockChange = () => {
    lockOpen(resume.locked ? "update" : "create", { id: "lock", item: resume });
  };

  const onDelete = () => {
    open("delete", { id: "resume", item: [resume] });
  };

  return (
    <ContextMenu>
      <ContextMenuTrigger>
        <BaseCard
          className="space-y-0"
          withShineBorder={resume.lockedPremium}
          onClick={() => {
            onOpen(shouldShowPremiumLock);
          }}
        >
          <AnimatePresence presenceAffectsLayout>
            <iframe
              ref={frameRef}
              title={resume.title}
              src="/artboard/preview"
              scrolling="no"
              style={{
                height: "90%",
                width: "90%",
                zoom: 0.35,
              }}
            />
          </AnimatePresence>
          <AnimatePresence>
            {resume.locked && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="absolute inset-0 flex items-center justify-center bg-background/75 backdrop-blur-sm"
              >
                <Lock size={42} />
              </motion.div>
            )}
          </AnimatePresence>

          <AnimatePresence>
            {shouldShowPremiumLock && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="absolute inset-0 flex items-center justify-center bg-background/75 backdrop-blur-sm"
              >
                <Lock size={42} />
              </motion.div>
            )}
          </AnimatePresence>

          <div
            style={{ height: "100%" }}
            className={cn(
              "absolute inset-x-0 bottom-0 z-10 flex flex-col justify-end space-y-0.5 p-4 pt-12",
              "bg-gradient-to-t from-background/80 to-transparent",
            )}
          >
            <h4 className="line-clamp-2 font-medium">{resume.title}</h4>
            <p className="line-clamp-1 text-xs opacity-75">{t`Last updated ${lastUpdated}`}</p>
          </div>
        </BaseCard>
      </ContextMenuTrigger>

      <ContextMenuContent>
        <ContextMenuItem
          onClick={() => {
            onOpen(shouldShowPremiumLock);
          }}
        >
          <FolderOpen size={14} className="me-2" />
          {t`Open`}
        </ContextMenuItem>
        <ContextMenuItem onClick={onUpdate}>
          <PencilSimple size={14} className="me-2" />
          {t`Rename`}
        </ContextMenuItem>
        <ContextMenuItem onClick={onDuplicate}>
          <CopySimple size={14} className="me-2" />
          {t`Duplicate`}
        </ContextMenuItem>
        {resume.locked ? (
          <ContextMenuItem onClick={onLockChange}>
            <LockOpen size={14} className="me-2" />
            {t`Unlock`}
          </ContextMenuItem>
        ) : (
          <ContextMenuItem onClick={onLockChange}>
            <Lock size={14} className="me-2" />
            {t`Lock`}
          </ContextMenuItem>
        )}
        <ContextMenuSeparator />
        <ContextMenuItem className="text-error" onClick={onDelete}>
          <TrashSimple size={14} className="me-2" />
          {t`Delete`}
        </ContextMenuItem>
      </ContextMenuContent>
    </ContextMenu>
  );
};
